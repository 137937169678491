import { Link } from "react-router-dom";
import { Navbar} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { toggleSearchBarOn, toggleSearchBarOff, setSearchValue, toggleOffcanvasOn} from '../actions';
import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import { useUserActions, getUser } from "../hooks/user.actions";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';



function useMediaQuery(query) {
    const [matches, setMatches] = useState(false);
    useEffect(
        () => {
        const mediaQuery = window.matchMedia(query);
        setMatches(mediaQuery.matches);
        const handler = (event) => setMatches(event.matches);
        mediaQuery.addEventListener("change", handler);
        return () => mediaQuery.removeEventListener("change", handler);
        },
      [] // Empty array ensures effect is only run on mount and unmount
    );
    return matches;
}





function Navbarx(props){

    const { withSearchBar, switchOnOff } = props;

    const dispatch = useDispatch();
    const searchBarState = useSelector(state => state.search)

    const [searchFieldValue, setSearchFieldValue] = useState("")


    const user = getUser();
    const userActions = useUserActions();


    const handleOnSearchIconClick = () => {
        if (searchBarState.bar){
            dispatch(toggleSearchBarOff())
        }
        else{
            dispatch(toggleSearchBarOn())
        }
    };


    const handleNavigateToProfile = () => {
        window.location.href = `/user/${user.id}/`
    };

    const handleNavigateHome = () => {
        window.location.href = `/`
    };


    const handleSearchBtnClicked = (event) =>{
        event.preventDefault();
        dispatch(setSearchValue(searchFieldValue))
    }

    const handleClearSearchBar = () =>{
        dispatch(setSearchValue(""))
        setSearchFieldValue("")
    }

    const handleNavigateToLogin = () => {
        window.location.href = "/login/";
    };

    const handleLogout = () => {
        userActions.logout()
        window.location.href = "/";
    };


    const handleNavigateToUpload = () => {
        window.location.href = "/upload/";
    };

    const screenWidthTrashpoint = useMediaQuery('(min-width: 576px)');
    
    return(
            <nav
            className="navbar fixed-top border-bottom border-2  border-dark"
            style={{ 
                backgroundColor: "#fcf5e1",
                }}>
                <div className="container-fluid">
                    <Navbar.Brand
                    className="navbar-brand"
                    id="navbar-brand"
                    onClick={handleNavigateHome}
                    style={{
                    fontFamily: '"Hachi Maru Pop", serif',
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: 25}}>
                        MeowTok
                    </Navbar.Brand>

                    
                    {!switchOnOff &&
                    
                    (((searchBarState.bar || screenWidthTrashpoint) && withSearchBar) ? 
                    
                        <div className=" flex-row justify-content-center align-items-center border border-2 border-dark"
                        // style={{marginTop: 100}}
                        id="search-bar">
                            {searchFieldValue && 
                                <svg
                                className="bi bi-x"
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                                style={{ fontSize: 31, color: "rgb(255,255,255)", background: "#2c2c2c", cursor: "pointer" }}
                                onClick={handleClearSearchBar}
                                >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                            }
    
    
                            <input 
                            className="border rounded-0"
                            type="text"
                            style={{ height: 31}}
                            onChange={(e)=>(setSearchFieldValue(e.target.value))}
                            value={searchFieldValue}
                            />
                            <button
                            className="btn btn-primary btn-sm border rounded-0"
                            type="button"
                            style={{ fontFamily: '"Hachi Maru Pop", serif',background: "#fcf5e1" }}
                            onClick={handleSearchBtnClicked}>
                                search
                            </button>
                        </div>
                        :
                        <></>
                        )
                    
                    }



                    { (withSearchBar && !switchOnOff) &&
                    <svg
                    className="bi bi-search border border-2 border-dark rounded-0"
                    id="search-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    style={{
                    fontSize: 40,
                    background: "transparent",
                    paddingRight: 5,
                    paddingLeft: 5,
                    // height: 34,
                    // width: "20%"
                }}
                    onClick={handleOnSearchIconClick}
                    >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>}



                        {
                            user?.id ?
                            <Dropdown drop={"down-centered"} className="d-none d-sm-none d-md-none d-lg-inline d-xl-inline d-xxl-inline">
                                <OverlayTrigger
                                placement={"left"}
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip>
                                        Upload
                                    </Tooltip>
                                }>
                                    <svg
                                    className="bi bi-upload border border-2 border-dark p-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                    style={{ marginRight: 40, fontSize: 35,cursor: "pointer"}}
                                    onClick={handleNavigateToUpload}>
                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                    </svg>
                                </OverlayTrigger>

                                <Dropdown.Toggle 
                                style={{
                                padding: 0,
                                marginRight: "10vw",
                                border: 0,
                                background: "transparent"}}>
                                    <Image
                                    className="rounded-circle border border-2 border-dark shadow-lg"
                                    style={{ width: 55, height: 55}}
                                    src={user.profile_pic?.substring(0 , user.profile_pic.lastIndexOf("?"))}
                                    alt={user.username}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                style={{marginTop: 6,
                                borderRadius: 0,
                                borderColor: "black",
                                borderWidth: "1px",
                                fontFamily: '"Hachi Maru Pop", serif',
                                background: "#fcf5e1",
                                padding: 0
                                }}>
                                    <Dropdown.Item id="drop-item"
                                    className="border border-2  border-dark d-flex flex-column  justify-content-center"
                                    style={{fontWeight: "bolder",
                                            textAlign: "center",
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            height: 80
                                        }}
                                    onClick={handleNavigateToProfile}>
                                        Profile
                                    </Dropdown.Item>

                                    <Dropdown.Item id="drop-item-logout"
                                    className="border border-top-0 border-2  border-dark"
                                    style={{fontWeight: "bolder",
                                    textAlign: "center",
                                    marginTop: 0,
                                    paddingTop: 10,
                                    paddingBottom: 10}}
                                    onClick={handleLogout}>
                                        Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            :
                            <Button
                            className="btn btn-primary btn-lg border-0 rounded-0 d-none d-sm-none d-md-none d-lg-inline d-xl-inline d-xxl-inline"
                            id="login-btn"
                            type="button"
                            as={Link}
                            onClick={handleNavigateToLogin}
                            style={{
                            marginLeft: 0,
                            marginRight: "10%",
                            paddingRight: 0,
                            paddingLeft: 0,
                            fontFamily: '"Hachi Maru Pop", serif',
                            borderStyle: "none"}}>
                                Login
                            </Button>  
                        }


                    <button className="navbar-toggler d-lg-none d-xl-none d-xxl-none collapsed border border-2 rounded-0 border-dark"
                    onClick={() => dispatch(toggleOffcanvasOn())}>
                        <span className="visually-hidden">Toggle navigation</span>
                        <span className="navbar-toggler-icon" />
                    </button>
                </div>
            </nav>
        )
    }



export default Navbarx;
