    import { useSelector, useDispatch } from "react-redux";
    import {
    setSrotbyNewest,
    setSrotbyLikes,
    setSrotbyViews,
    setSrotbytag,
    } from "../actions";
    import React, { useState, useEffect } from "react";
    import { getUser } from "../hooks/user.actions";
    import axios from "axios";

    function Sidebar(props) {


    const { activeSort, tag } = props

    const dispatch = useDispatch();
    const sortbyState = useSelector((state) => state.sortby);

    const [tags, setTags] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleNavigateToUsers = () => {
        window.location = `/users/`;
    };

    const handleNavigateToExplore = () => {
        window.location.href = "/videos/explore/";
    };

    const handleNavigateToFollowing = () => {
        window.location.href = "/videos/following/";
    };

    const user = getUser();

    useEffect(() => {
        const getData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(
            process.env.REACT_APP_BASE_URL + "/video/trending_tgs24"
            );
            setTags(response.data.results);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
        };
        getData();
    }, []);




    const navigateToLikes = () =>{
        window.location.href = `/likes/`
    }


    const navigateToViews = () =>{
        window.location.href = `/views/`
    }


    const navigateToNewest = () =>{
        window.location.href = `/`
    }

    const randomInt = (max) => {
        return Math.floor(Math.random() * max) + 4;
    };


    return (
        <div
        id="sidebar"
        className="border-start border-2  border-dark  col-3 d-none d-sm-none d-md-none d-lg-flex d-xl-flex d-xxl-flex flex-column "
        style={{
            marginTop: 105,
            height: "85%",
            marginRight: 0,
        }}
        >
        <div
            className="row"
            style={{
            marginRight: 0,
            marginLeft: 0,
            marginTop: 10,
            width: "100%",
            }}
        >
            <small
            className="d-flex justify-content-center align-items-center"
            id="sortby"
            style={{
                fontFamily: '"Hachi Maru Pop", serif',
                fontWeight: "bold",
                fontSize: 14,
            }}
            >
            Sort by&nbsp;
            </small>

            {activeSort === "tag" ? (
            <div
                className="col d-flex flex-row justify-content-center align-items-start flex-wrap"
                style={{ height: "100%" }}
            >
                <small
                id="small-tag"
                className="text-break"
                style={{
                    fontFamily: '"Hachi Maru Pop", serif',
                    fontWeight: "bold",
                    fontSize: 20,
                    background: "#1c80e4",
                    borderWidth: 3,
                    borderStyle: "solid",
                    paddingRight: 18,
                    paddingLeft: 18,
                    paddingTop: 5,
                    paddingBottom: 5,
                    marginTop: 7,
                }}
                >
                {tag}
                </small>
            </div>
            ) : (
            <div
                className="col d-flex flex-row justify-content-center"
                style={{ paddingRight: 0, paddingLeft: 0, height: 25 }}
            >
                <button
                id="sort-by-tag"
                className="text-break d-flex flex-column justify-content-end align-items-center"
                style={{
                    fontFamily: '"Hachi Maru Pop", serif',
                    fontWeight: "bold",
                    borderWidth: 2,
                    borderStyle: "solid",
                    marginRight: 0,
                    background: activeSort === "newest" ? "#1c80e4" : "transparent",
                }}
                onClick={() => navigateToNewest()}

                >
                newest
                </button>
                <button
                id="sort-by-tag"
                className="text-break d-flex flex-column justify-content-end align-items-center"
                style={{
                    fontFamily: '"Hachi Maru Pop", serif',
                    fontWeight: "bold",
                    borderWidth: 2,
                    borderStyle: "solid",
                    marginRight: 0,
                    background: activeSort === "likes" ? "#1c80e4" : "transparent",
                }}
                onClick={() => navigateToLikes()}
                >
                likes
                </button>
                <button
                id="sort-by-tag"
                className="text-break d-flex flex-column justify-content-end align-items-center"
                style={{
                    fontFamily: '"Hachi Maru Pop", serif',
                    fontWeight: "bold",
                    borderWidth: 2,
                    borderStyle: "solid",
                    marginRight: 0,
                    background: activeSort === "views" ? "#1c80e4" : "transparent",
                }}
                onClick={() => navigateToViews()}
                >
                views
                </button>
            </div>
            )}

        </div>
        <div className="row" style={{ marginTop: 35 }}>
            <div
            className="col d-flex flex-column align-items-strerch "
            id="explore-users-column"
            >
            <button
                id="sidebar-btn"
                className="btn btn-primary btn-lg  rounded-0"
                type="button"
                style={{
                fontFamily: '"Hachi Maru Pop", serif',
                color: "rgb(0,0,0)",
                fontWeight: "bold",
                borderWidth: 2,
                borderColor: "black",
                fontSize: 18,
                }}
                onClick={handleNavigateToExplore}
            >
                Explore
            </button>

            {user?.id && (
                <button
                id="sidebar-btn"
                className="btn btn-primary btn-lg  rounded-0"
                type="button"
                style={{
                    marginTop: 10,
                    fontFamily: '"Hachi Maru Pop", serif',
                    color: "rgb(0,0,0)",
                    fontWeight: "bold",
                    borderWidth: 2,
                    borderColor: "black",
                    fontSize: 18,
                }}
                onClick={handleNavigateToFollowing}
                >
                Following
                </button>
            )}

            <button
                id="sidebar-btn"
                className="btn btn-primary btn-lg  rounded-0"
                type="button"
                style={{
                marginTop: 10,
                fontFamily: '"Hachi Maru Pop", serif',
                color: "rgb(0,0,0)",
                fontWeight: "bold",
                borderWidth: 2,
                borderColor: "black",
                fontSize: 18,
                }}
                onClick={handleNavigateToUsers}
            >
                Users
            </button>
            </div>
        </div>

        <div
            className="row"
            style={{
            marginTop: 31,
            height: "60%",
            overflow: "hidden",
            paddingLeft: 10,
            }}
        >
            <div
            className="col d-flex flex-column justify-content-start align-items-stretch"
            style={{ height: "100%" }}
            >
            <small
                className="text-center"
                style={{
                fontFamily: '"Hachi Maru Pop", serif',
                fontWeight: "bold",
                fontSize: 22,
                marginBottom: 13
                }}
            >
                Trending tags
            </small>





            <div className="row" style={{ height: "100%" }}>
                <div
                className="d-flex flex-row justify-content-center align-items-start align-content-start flex-wrap"
                style={{ height: "80%", paddingLeft: 30, paddingRight: 30 }}
                >
                {tags.map((tag) => (
                    <small
                    id="small-tag"
                    className="text-break"
                    style={{
                        fontFamily: '"Hachi Maru Pop", serif',
                        fontWeight: "bold",
                        borderWidth: 2,
                        borderStyle: "solid",
                        paddingRight: 8,
                        paddingLeft: 8,
                        marginRight: randomInt(15),
                        marginLeft: randomInt(15),
                        marginBottom: randomInt(8),

                        cursor: "pointer",
                    }}
                    onClick={() => dispatch(setSrotbytag(tag.name))}>
                    {tag.name}
                    </small>
                ))}

                </div>
            </div>
            </div>


        </div>
        <div className="row" >
            <div
            className="col d-flex flex-column justify-content-end"
            style={{ height: "auto", paddingRight: 79, paddingLeft: 80 }}
            >
            <button
                id="sidebar-btn"
                className="btn btn-primary btn-sm  rounded-0"
                type="button"
                style={{
                // marginBottom: 130,
                fontFamily: '"Hachi Maru Pop", serif',
                color: "rgb(0,0,0)",
                fontWeight: "bold",
                borderWidth: 2,
                borderColor: "black",
                }}
            >
                Terms of use
            </button>
            </div>
        </div>
        </div>
    );
    }

    export default Sidebar;
