import React from "react";
import { Route, Routes } from "react-router-dom";
import Login  from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Upload from "./pages/Upload";
import UserProfile from "./pages/UserProfile";
import ImageUpload from "./componenets/ImageUpload";
import Users from "./pages/Users";
import Videos from "./pages/Videos";
import PasswordReset from "./pages/PasswordReset";


function App() {
  return (
      <Routes>
        <Route path="/login/" element={ <Login /> }/>
        <Route path="/register/" element={ <Register /> }/>
        <Route exact  path="/" element={ <Home /> }/>
        <Route exact  path="/:sortBy" element={ <Home /> }/>
        <Route exact  path="/tag/:tag" element={ <Home /> }/>
        <Route path="/upload/" element={ <Upload />  }/>
        <Route path="/user/:userId/" element={ <UserProfile />  }/>
        <Route path="/image/" element={ <ImageUpload />  }/>
        <Route path="/users/" element={ <Users />  }/>
        <Route path="/videos/:videoStream" element={ <Videos />  }/>
        <Route path="/passwordreset/:token" element={ <PasswordReset />  }/>
      </Routes>
  );
}



export default App;



